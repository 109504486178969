<template>
    <CCard>
        <CCardHeader>
            Şikayetler
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol class='col-12 col-lg-6'>
                    <CInput label='Arama: ' placeholder='Konu Başlığı' :horizontal="{ label: 'w-auto', input: 'w-100'}" v-debounce:600ms='changeFilterValue' />
                </CCol>
                <CCol class='col-12 col-lg-6'>
                    <CRow>
                        <CCol class='col-12 col-lg-6'>
                            <CSelect
                                label='Rol: '
                                placeholder='Seçim Yapınız'
                                :horizontal="{ label: 'w-auto', input: 'w-100'}"
                                @update:value='filteredComplaintsRole($event)'
                                :options="[
                          { value: null, label: 'Hepsi'},
                          { value: 'Student', label: 'Öğrenci'},
                          { value:'Teacher', label: 'Öğretmen'}
                        ]"
                            />
                        </CCol>
                        <CCol class='col-12 col-lg-6'>
                            <CSelect
                                label='Durum: '
                                placeholder='Seçim Yapınız'
                                :horizontal="{ label: 'w-auto', input: 'w-100'}"
                                @update:value='filteredComplaintStatus($event)'
                                :options="[
                          { value: null, label: 'Hepsi'},
                          { value: 'NOT_SEEN_YET', label: 'Görülmedi'},
                          { value: 'REJECTED', label:'Reddedildi'},
                          { value: 'COMPLETED', label:'Tamamlandı'},
                          { value: 'SEEN', label: 'Görüldü'}
                        ]"
                            />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CDataTable
                class='mb-0 table-outline'
                hover
                :items='tableItems'
                :fields='tableFields'
                head-color='light'
                :items-per-page='itemsPerPage'
                :loading='isDataLoading'

            >
                <template #no-items-view>
                    <CRow>
                        <CCol class='col-12 font-3xl text-center'>
                            <CIcon name='cil-x' />
                            Veri Bulunamadı
                        </CCol>
                    </CRow>
                </template>
                <td slot='complainId' slot-scope='{item}'>
                    <div>{{ item.complainId }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td
                    slot='complaintTitle'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <div>{{ item.complaintTitle }}</div>
                </td>
                <td slot='complainant' slot-scope='{item}'>
                    <div>{{ item.complainant.complaininatName }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td slot='complained' slot-scope='{item}'>
                    <div>{{ item.complained.complainedName }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td slot='complaintDate' slot-scope='{item}'>
                    <div>{{ item.complaintDate | formatDate }}</div>
                    <div class='small text-muted'>
                    </div>
                </td>
                <td
                    slot='complaintCategory'
                    slot-scope='{item}'
                    class='text-center'
                >
                    <CBadge :color='gotBadge(item.complaintCategory)'>{{ gotText(item.complaintCategory) }}</CBadge>
                </td>

                <td slot='complaintState' slot-scope='{item}'>
                    <CBadge :color='catBadge(item.complaintState)'>{{ catText(item.complaintState) }}</CBadge>
                </td>
                <td slot='manage' slot-scope='{item}'>
                    <router-link :to="'/randevu-sikayetleri/' + item.complainId" tag='button' role='button' block
                                 class='btn btn-success'>
                        <CIcon name='cil-pencil' />
                    </router-link>
                </td>
            </CDataTable>
            <CPagination
                v-if='totalPages !== 0'
                :pages='totalPages'
                :active-page='currentPage'
                :activePage.sync='currentPage'
                align='center'
                class='mt-3'
            />
        </CCardBody>
    </CCard>
</template>

<script>
import { CustomerServiceAdministration } from '@/services/api-service'

export default {
    name: 'Complaints',
    components: {},
    mounted() {
        this.loadComplaints()
    },
    watch: {
        itemsPerPage() {
            this.loadComplaints()
        },
        currentPage() {
            this.loadComplaints()
        },
        filterValue() {
            this.loadComplaints()
        },
        filtersListComplaintsStatus() {
            this.loadComplaints()
        },
        filtersListComplaintsRole() {
            this.loadComplaints()
        },
    },
    data() {
        return {
            noDataFound: 'Veri Bulunamadı',
            showNotSeenComplaints: null,
            showSeenComplaints: null,
            showRejectedComplaints: null,
            showCompletedComplaints: null,
            showTeachers: null,
            showStudents: null,
            filtersListComplaintsRole: undefined,
            filtersListComplaintsStatus: undefined,
            filterValue: '',
            currentPage: 1,
            itemsPerPage: 50,
            totalPages: 1,
            isDataLoading: false,
            tableItems: [],
            tableFields: [
                { key: 'complainId', label: 'ID', _classes: 'text-center' },
                { key: 'complaintTitle', label: 'Konu Başlığı', _classes: 'text-center' },
                { key: 'complainant', label: 'Şikayet Eden Kişi' },
                { key: 'complained', label: 'Şikayet Edilen Kişi' },
                { key: 'complaintDate', label: 'Şikayet Tarihi' },
                { key: 'complaintCategory', label: 'Şikayet Kategorisi', _classes: 'text-center' },
                { key: 'complaintState', label: 'Durum' },
                { key: 'manage', label: 'Yönet' },
            ],
        }
    },
    methods: {
        async filteredComplaintStatus(value) {
            this.filtersListComplaintsStatus = value == null ? undefined : value
        },
        async filteredComplaintsRole(value) {
            this.filtersListComplaintsRole = value == null ? undefined : value
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        gotBadge(category) {
            switch (category) {
                case 'CHAT':
                    return 'info'
                case 'APPOINTMENT':
                    return 'primary'
                case 'USER':
                    return 'secondary'
                default:
                    return 'danger'
            }
        },
        gotText(category) {
            switch (category) {
                case 'USER':
                    return 'Kullanıcı'
                case 'APPOINTMENT':
                    return 'Randevu'
                case 'CHAT':
                    return 'CHAT'
                default:
                    return ''
            }
        },
        catBadge(status) {
            switch (status) {
                case 'COMPLETED':
                    return 'success'
                case 'NOT_SEEN_YET':
                    return 'warning'
                case 'REJECTED':
                    return 'danger'
                case 'SEEN':
                    return 'info'
                default:
                    return 'danger'
            }
        },
        catText(status) {
            switch (status) {
                case 'COMPLETED':
                    return 'Tamamlandı'
                case 'NOT_SEEN_YET':
                    return 'Görülmedi'
                case 'REJECTED':
                    return 'Reddedildi'
                case 'SEEN':
                    return 'Görüldü'
                default:
                    return 'Görülmedi'
            }
        },

        async loadComplaints() {
            this.isDataLoading = true
            try {
                const result = await CustomerServiceAdministration.listComplaints(
                    this.currentPage,
                    this.itemsPerPage,
                    this.filterValue,
                    this.filtersListComplaintsRole == null ? undefined : this.filtersListComplaintsRole.toUpperCase(),
                    this.filtersListComplaintsStatus,
                )
                this.tableItems = result.data.complaintList
                this.totalPages = result.data.pagination.totalPages
            } catch (e) {
                console.log(e)
            }

            this.isDataLoading = false
        },
        changeFilterValue(newValue) {
            this.filterValue = newValue
        },
    },
}
</script>

